'use client';
import { useCallback, useContext, useEffect, useState } from 'react';

import { setCookie, getCookie } from 'cookies-next';
import { useTranslation } from 'next-i18next';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { ConsentContext } from 'pages/_app';
import * as CookieConsent from 'vanilla-cookieconsent';

import { useCookieTranslation } from './useCookieTranslation';
import { IAnyType } from 'common/types/common';

export const Cookies: React.FC = () => {
  const { handleConsent } = useContext(ConsentContext);
  const { i18n } = useTranslation('common');
  const { hr, en } = useCookieTranslation();
  const searchParams = useSearchParams();
  const router = useRouter();

  const [consentVisible, setConsentVisible] = useState(true);
  const [cookies, setCookies] = useState({
    analytics: false,
    marketing: false,
    functionality: false,
  });

  const gtmScriptEnabled =
    typeof window !== 'undefined' &&
    !window.location?.hostname?.includes('staging') &&
    searchParams.get('cookie-consent') !== 'false';

  const setBrowserCookie = useCallback(
    (
      {
        analytics,
        marketing,
        functionality,
      }: {
        analytics: boolean;
        marketing: boolean;
        functionality: boolean;
      },
      initial: boolean,
    ) => {
      const initialState = getCookie('browser-cookie');
      setCookie(
        'browser-cookie',
        JSON.stringify(
          `analytics:${analytics},functionality:${functionality},marketing:${marketing}`,
        ),
      );

      if (!initial) {
        const state = initialState
          ?.replace(/"/gi, '')
          .split(',')
          .reduce((acc: any, item: string) => {
            const [key, value] = item.split(':');
            acc[key] = value === 'true';
            return acc;
          }, {});
        if (state.analytics !== analytics || state.marketing !== marketing) {
          router.reload();
        }
      }

      setCookies({ analytics, marketing, functionality });
      if (analytics || marketing) {
        handleConsent(true);
      }
      const win = window as IAnyType;
      if (typeof win !== 'undefined' && win.dataLayer) {
        win.dataLayer.push({
          event: 'consent_update',
          consentStatus: { analytics, functionality, marketing },
        });
      }
    },
    [cookies, router],
  );

  useEffect(() => {
    if (gtmScriptEnabled) {
      setTimeout(() => {
        CookieConsent.run({
          guiOptions: {
            consentModal: {
              layout: 'box',
              position: 'middle center',
              equalWeightButtons: false,
              flipButtons: true,
            },
            preferencesModal: {
              layout: 'box',
              position: 'right',
              equalWeightButtons: true,
              flipButtons: true,
            },
          },
          onModalShow: () => {
            setConsentVisible(false);
          },
          onModalHide: data => {
            if (data.modalName === 'preferencesModal') {
              const acceptedCategories = CookieConsent.getUserPreferences().acceptedCategories;
              setBrowserCookie(
                {
                  analytics: acceptedCategories.includes('analytics'),
                  marketing: acceptedCategories.includes('marketing'),
                  functionality: true,
                },
                false,
              );
            }

            setConsentVisible(true);
          },
          onConsent: ({ cookie: { categories } }: any) => {
            setBrowserCookie(
              {
                analytics: categories.includes('analytics'),
                marketing: categories.includes('marketing'),
                functionality: true,
              },
              true,
            );
          },
          categories: { functionality: { readOnly: true }, analytics: {}, marketing: {} },
          language: { default: i18n.language, translations: { hr, en } },
        });
      }, 2000);
    }
  }, [hr, en, i18n.language]);

  return (
    <button
      aria-label="show-preferencesModal"
      className={`cookie-btn ${consentVisible ? 'cookie-btn-visible' : ''}`}
      data-cc="show-preferencesModal">
      <svg
        width="25"
        height="25"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.9584 35.708C8.19071 35.708 0.291626 27.785 0.291625 17.9997L0.291625 16.0266L1.6547 16.2999C1.7269 16.3144 1.79254 16.3278 1.85292 16.3402C2.18947 16.4089 2.3624 16.4442 2.59351 16.4442C4.02238 16.4442 5.20017 15.575 5.69593 14.3946L6.08757 13.4621L7.05986 13.7406C7.54336 13.8791 8.01924 13.9464 8.49032 13.9464C11.5129 13.9464 13.9955 11.4616 13.9955 8.42451C13.9955 7.9478 13.9288 7.53472 13.8448 7.02934L13.7178 6.2655L14.3813 5.86637C15.2722 5.33053 15.9147 4.28559 15.9886 3.06722C15.9835 2.70604 15.8823 2.37723 15.7147 1.87315L15.3081 0.650195L16.5717 0.396836C17.0992 0.291083 17.6116 0.291225 18.0046 0.291332L18.0415 0.29134C27.8092 0.29134 35.7083 8.21439 35.7083 17.9997C35.7083 27.7924 27.7187 35.708 17.9584 35.708ZM2.58643 18.7225C2.96219 26.9183 9.69584 33.4297 17.9584 33.4297C26.47 33.4297 33.43 26.5247 33.43 17.9997C33.43 9.53184 26.6494 2.67413 18.2343 2.57079C18.2549 2.73864 18.267 2.91385 18.267 3.09572L18.267 3.1256L18.2655 3.15544C18.1785 4.81232 17.4196 6.35187 16.2006 7.36124C16.2425 7.68459 16.2737 8.03839 16.2737 8.42451C16.2737 12.7145 12.7765 16.2246 8.49032 16.2246C8.1069 16.2246 7.72496 16.1941 7.34497 16.1338C6.35851 17.6674 4.62516 18.7225 2.59351 18.7225C2.59115 18.7225 2.58878 18.7225 2.58643 18.7225Z"
          fill="white"
        />
        <path
          d="M11.4052 2.5294C11.4052 1.35045 10.4495 0.394729 9.27053 0.394729C8.09159 0.394729 7.13586 1.35045 7.13586 2.5294C7.13586 3.70834 8.09159 4.66406 9.27053 4.66406C10.4495 4.66406 11.4052 3.70834 11.4052 2.5294Z"
          fill="white"
        />
        <path
          d="M10.2426 9.19713C10.2426 8.33923 9.54712 7.64376 8.68923 7.64376C7.83133 7.64376 7.13586 8.33923 7.13586 9.19713C7.13586 10.055 7.83133 10.7505 8.68923 10.7505C9.54712 10.7505 10.2426 10.055 10.2426 9.19713Z"
          fill="white"
        />
        <path
          d="M4.12412 10.2803C4.12412 9.39614 3.40733 8.67935 2.52312 8.67935C1.63891 8.67935 0.922119 9.39614 0.922119 10.2803C0.922119 11.1646 1.63891 11.8813 2.52312 11.8813C3.40733 11.8813 4.12412 11.1646 4.12412 10.2803Z"
          fill="white"
        />
        <path
          d="M16.7148 17.2523L17.7308 19.1024L18.35 20.2155L23.1603 12H26.8752L20.1439 23.4923H16.429L12.9999 17.2523H16.7148Z"
          fill="white"
        />
        <path d="M19.9414 25.7905H16.4725V29.2382H19.9414V25.7905Z" fill="white" />
      </svg>
    </button>
  );
};
